@import "./icons.scss";
@import "./variables.scss";
@import "./mixin.scss";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

* {
  font-family: "roboto condensed", sans-serif;
}

button {
  background-color: $theme1-bg;
  border-color: $theme1-bg;
  color: $primary-color;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  margin-bottom: 20px;
  margin: 1;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn {
  background-color: $theme1-bg;
  border-color: $theme1-bg;
  color: $primary-color;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  margin: 1;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.chakra-modal__close-btn {
color: black;
}
.dark {
  background-color: $theme2-bg !important;
  border-color: $theme2-bg;
}

.chakra-button.primary {
  background-color: $primaryYellow;
  border-radius: 0;
}
.chakra-text,.chakra-form__label{
  font-size: medium !important;
}
.login_bg {
  background-color: $primaryBtnColor;
  height: 100vh;
  width: 100%;

  .login_container {
    margin: 0 auto;
    width: 400px;
    padding: 100px 0 0;

    .login_logo {
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        margin: 0;
        max-width: 250px;
        max-height: 100px;
      }
    }

    .login_box {
      width: 100%;
      padding: 35px 30px 10px;
      box-sizing: border-box;
      margin-top: 15px;
      background-color: #eee;
      border-radius: 4px;
      box-shadow: 0 0 4px #fff;
      button{
        background-color: #000;
        color: white;
      }

      input {
        display: block;
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        margin-bottom: 20px;
      }

      h2 {
        color: #000;
        position: relative;
        margin-bottom: 30px;
        text-align: center;
        font-weight: 500;
        line-height: 1.2;
        font-size: 2rem;
      }

      h2::before {
        content: "";
        height: 1px;
        position: absolute;
        top: 23px;
        width: 20%;
        left: 0;
        background: #000;
      }

      h2::after {
        content: "";
        height: 1px;
        position: absolute;
        top: 23px;
        width: 20%;
        background: #000;
        right: 0;
      }
    }
  }
}

.header_wrapper {
  background-color: $theme1-bg;
  padding: 8px 0;
  position: relative;
  color: $primary-color;
  display: flex;
  padding-right: 15px;

  .container_fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 150px;

    .logo {
      position: absolute;
      left: 5px;
      top: 6px;
      z-index: 11;

      img {
        max-width: 120px;
        height: 40px;
      }
    }

    .side_menu_button {
      cursor: pointer;
      position: absolute;
      left: 135px;
      top: 15px;
      z-index: 10;
      border: none;

      .bar1,
      .bar2,
      .bar3 {
        width: 20px;
        height: 3px;
        background-color: $primary-color;
        margin: 4px 0;
        transition: 0.4s;
      }
    }

    .navbar {
      position: relative;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: justify;
      justify-content: flex-start;

      p {
        padding: 5px;
      }
    }
  }
}

.menu_button:hover {
  background-color: $primaryBtnColor;
}

.home_collapsible {
  background-color: $theme2-bg;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
}

.col-md-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.title_bar {
  background-color: $theme2-bg;
  color: $secondary-color;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 700;
}

.lay {
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  padding: 6px 0;
  padding-left: 0;
  text-indent: -6px;
  min-height: 30px;
}

.switch {
  border-radius: 0px;
  border-bottom: 1px solid $theme1-bg;
  background: transparent;
}

.switch_active {
  background-color: $theme1-bg;
  color: $primary-color;
  border-radius: 0px;
}

.mgbtn {
  margin: 2;
}
